<template>
  <van-tabbar route>
    <van-tabbar-item replace to="/" icon="wap-home">Home</van-tabbar-item>
    <van-tabbar-item replace to="/bar" icon="bars">Products</van-tabbar-item>
    <van-tabbar-item replace to="/about-contact" icon="service"
      >Contact Us</van-tabbar-item
    >
  </van-tabbar>
</template>

<script>
import { Tabbar, TabbarItem } from "vant";
export default {
  components: {
    [Tabbar.name]: Tabbar,
    [TabbarItem.name]: TabbarItem,
  },
  name: "Siteabbar",
  props: {
    idx: {
      type: Number,
      default: 0,
    },
  },
  setup() {},
  created() {},
};
</script>
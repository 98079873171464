import axios from 'axios'
import { Toast, Dialog } from 'vant';
import { getToken, setToken } from '@/utils/auth'
import {router} from '../router'
import store from '../store'

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  withCredentials: true, // send cookies when cross-domain requests
  timeout: 5000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    var token = getToken();
    if (typeof(token) !== "undefined")
    {
        config.headers['Authorization'] =  token
    }
    // do something before request is sent
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data

    // if the custom code is not 20000, it is judged as an error.
    if (res.code !== 20000) {
      //参数错误
      /*
      if (res.code === 10000) { 
        Dialog.alert({
          message: res.message || 'Error'
        })
        return res
      } 
      */
      // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
      if (res.code === 50008 || res.code === 50012 || res.code === 50014) {
        setToken('');
        Dialog.alert({
          confirmButtonText: '登录',
          message:'登录后可继续访问本页面。',
        })
        .then(() => {
          router.beforeEach((to, from, next) => {
            const title = to.meta && to.meta.title;
            if (title) {
              document.title = title;
            }
            if(to.fullPath === "/login")
            {
              let fromUrl = from.fullPath;
              //setLocalString(fromUrl)
              store.commit("user/setFromUrl", fromUrl);

            }
            next();
          });
          router.push({path: "/login"});
        })
        .catch(() => {
          // on cancel
        });
      }else{        
        Dialog.alert({
          message: res.message || 'Error'
        })
      }
      //return Promise.reject(new Error(res.message || 'Error')).catch(()=>{})
      return res
    } else {
      if (typeof res.uptoken !== 'undefined') {
        setToken(res.uptoken);
      }
      return res
    }
  },
  error => {
    console.log('err' + error) // for debug
    Toast(error.message);
    return Promise.reject(error)
  }
)

export default service
